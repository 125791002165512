import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import styled from 'styled-components';

import {
  Dashboard,
  DownloadsView,
  ErrorView,
  HomeView,
  LoginView,
  MetricsView,
  NoMatchView,
  View
} from '@4sure/dashboard-core';

import {
  DownloadsIcon as DL,
  // OperationsIcon as OL,
  PerformanceIcon as PL,
  WipIcon as WL,
  Logo
} from '@4sure/ui-kit';

import BuildersLogo from './static/Builders_logo_SA_RGB.svg';

import * as serviceWorker from './serviceWorker';
import { store } from './state';

// ################
// Telemetry
const isProduction = process.env.NODE_ENV === 'production';
const orgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
// future use
const LogoWrapper = styled.div`
  padding-top: ${props => props.theme.spacing(3)}px;

  img {
    height: ${props => props.height || 12}px;
    margin: auto;
  }
`;
const BuildersWLogo = () => (
  <LogoWrapper height={64}>
    <Logo logoSrc={BuildersLogo} />
  </LogoWrapper>
);

const StyledIcon = IconComponent => styled(IconComponent).attrs({
  height: 26,
  width: 26
})`
  margin: ${props => props.theme.spacing(1)}px;
`;

// const OperationsIcon = StyledIcon(OL);
const WipIcon = StyledIcon(WL);
const PerformanceIcon = StyledIcon(PL);
const DownloadsIcon = StyledIcon(DL);

const LoginPage = styled(LoginView).attrs(() => ({
  subtitle: '4-Sure Statistics and Reports',
  poweredBy: true,
  MainLogo: <BuildersWLogo />
}))``;

const userId = Date.now();

if (isProduction && sentryDsn) {
  Sentry.init({ dsn: sentryDsn });
}

// Identify users in FullStory by their email, if available.
if (isProduction && orgId) {
  FullStoryAPI('identify', userId);
}
// END Telemetry
// ################

// Instantiate the Redux store, including all
// reducers, middleware, enhancers, etc.

ReactDOM.render(
  <>
    {isProduction && orgId && <FullStory org={orgId} />}
    <Dashboard store={store}>
      <View
        pathFragment="/downloads"
        protectedRoute={true}
        component={<DownloadsView />}
        title="Downloads"
        tags={['Downloads', 'reports']}
        subSection="Reports"
        icon={DownloadsIcon}
      />

      <View pathFragment="/metrics" protectedRoute={true}>
        <View
          pathFragment="/wip"
          component={
            <MetricsView
              boardName={'metric'}
              metricsToLoad={[
                'appointments_made',
                'appointments_met',
                'appointments_missed',
                'might_miss_appointments',
                'pending',
                'en_route',
                'awaiting_stock',
                'payments_to_complete'
              ]}
              pageTitle={'WIP Metrics'}
              pageDescription={'Gain insights into your business with 4-Sure.'}
              keywords={['metrics', 'business Wip']}
              resolution={'month to date'}
            />
          }
          title="WIP"
          tags={['metrics', 'graphs', 'wip']}
          subSection="metrics"
          icon={WipIcon}
        />
        <View
          pathFragment="/performance"
          component={
            <MetricsView
              boardName={'metric'}
              metricsToLoad={[
                'total_installations_registered',
                'total_open_claims',
                'unallocated_installations',
                'no_appointment_call_customer',
                'installations_completed',
                'customer_e2e',
                'claim_e2e',
                'ftir'
              ]}
              pageTitle={'Performance Metrics'}
              pageDescription={'Gain insights into your business with 4-Sure.'}
              keywords={['metrics', 'business performance']}
              resolution={'month to date'}
              renderResolution={true}
            />
          }
          title="Performance"
          tags={['metrics', 'performance']}
          subSection="metrics"
          icon={PerformanceIcon}
        />
      </View>

      <View pathFragment="/login" component={<LoginPage />} />

      <View pathFragment="/error" component={<ErrorView />} />

      <View pathFragment="/" component={<HomeView />} protectedRoute={true} />
      <View pathFragment="*" component={<NoMatchView />} />
    </Dashboard>
  </>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
